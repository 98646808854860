<template>
  <v-app>
    <x-nav-bar />
    <v-main>
      <!-- route outlet -->
      <!-- component matched by the route will render here -->
      <router-view></router-view>
    </v-main>
    <!-- Footer block -->
    <x-footer :settings="footerSettings" />
  </v-app>
</template>

<script>
import XNavBar from '@/components/XNavBar.vue'
import XFooter from '@/components/XFooter.vue'
export default {
  name: 'App',

  components: {
    XNavBar,
    XFooter
  },

  data: function() {
    return {
      footerSettings: {
        type: 'normal'
      }
    }
  }
}
</script>
