<template>
  <v-container pa-0 ma-0 fill-height fluid>
    <section class="lg:tw-mt-24 sm:tw-mt-24 tw-w-full sm:tw-pb-24">
      <v-row class="mb-10">
        <v-col cols="12" xl="3" lg="3" sm="6">
          <v-card height="100%" class="px-5 pt-12 pb-10 tw-bg-white" tile>
            <v-img
              src="../assets/tech-advisor.svg"
              max-height="60"
              max-width="60"
            />
            <p class="tw-text-lg tw-pt-5 tw-font-semibold">Tech Advisory</p>
            <p class="">
              AUTOMATON provides the world's top-class experts in developing
              applications, providing technical expertise. We also assist in
              developing and implementing projects and offer our expertise for
              product development.
            </p>
            <p class="underline"></p>
          </v-card>
        </v-col>
        <v-col cols="12" xl="3" lg="3" sm="6">
          <v-card height="100%" class="px-5 pt-12 pb-10 tw-bg-white" tile>
            <v-img
              src="../assets/incubator.svg"
              max-height="60"
              max-width="60"
            />
            <p class="tw-text-lg tw-pt-5 tw-font-semibold">Incubator</p>
            <p class="">
              AUTOMATON analyzes client requirements and extensively researches
              to guide them every step of the way. We look for ingenious and
              inspired ideas to invest in and offer technical support - market
              research and trials and help businesses secure funds.
            </p>
            <p class="underline"></p>
          </v-card>
        </v-col>
        <v-col cols="12" xl="3" lg="3" sm="6">
          <v-card height="100%" class="px-5 pt-12 pb-10 tw-bg-white" tile>
            <v-img src="../assets/product.svg" max-height="60" max-width="60" />
            <p class="tw-text-lg tw-pt-5 tw-font-semibold">
              Product Development
            </p>
            <p class="">
              AUTOMATON helps new and startup companies to develop their
              business projects and offer business assistance services like
              scaling the businesses, prototyping, and much more. We specialise
              in the delivery of customised Products, Mobile &amp; Web App
              Development.
            </p>
            <p class="underline"></p>
          </v-card>
        </v-col>
        <v-col cols="12" xl="3" lg="3" sm="6">
          <v-card class="px-5 pt-12 pb-10" color="#77045A" tile>
            <p class="tw-text-white tw-text-2xl">
              Get expert advice with AUTOMATON
            </p>
            <p class="underline-white"></p>
          </v-card>
        </v-col>
      </v-row>
      <p
        class="tw-text-2xl tw-text-pink-800 tw-font-semibold tw-text-center mb-14 lg:tw-mt-24 sm:tw-mt-24"
      >
        Services
      </p>
      <v-row class="lg:tw-mb-24">
        <v-col cols="12" sm="6" class="lg:tw-hidden">
          <v-card color="transparent" elevation="0">
            <p class="tw-text-lg tw-font-semibold mb-2">Technical Advisory</p>
            <p class="">
              We offer the know-how and resources you need to design a solid
              product.
            </p>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="lg:tw-hidden sm:tw-text-right">
          <v-card color="transparent" elevation="0">
            <p class="tw-text-lg tw-font-semibold mb-2">Product Development</p>
            <p class="">
              We specialise in the delivery of customised Products, Mobile &amp;
              Web App Development.
            </p>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          sm="12"
          class="lg:tw-flex lg:tw-flex-col lg:tw-justify-between lg:tw-text-right sm:tw-hidden tw-hidden"
        >
          <v-card color="transparent" elevation="0">
            <p class="tw-text-lg tw-font-semibold mb-2">Technical Advisory</p>
            <p class="">
              We offer the know-how and resources you need to design a solid
              product.
            </p>
          </v-card>
          <v-card color="transparent" elevation="0">
            <p class="tw-text-lg tw-font-semibold mb-2">Product Development</p>
            <p class="">
              We specialise in the delivery of customised Products, Mobile &amp;
              Web App Development.
            </p>
          </v-card>
        </v-col>
        <v-col cols="12" xl="6" lg="6" sm="12" class="tw-flex tw-flex-col">
          <v-card color="transparent" elevation="0" class="tw-py-8 tw-mx-auto">
            <v-img
              src="../assets/spider-design.svg"
              max-height="475"
              max-width="640"
            />
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" class="lg:tw-hidden">
          <v-card color="transparent" elevation="0">
            <p class="tw-text-lg tw-font-semibold mb-2">Product Consulting</p>
            <p class="">
              Our Expertise is to materialise innovative ideas, standardise
              technology stack, fast-track delivery time to market.
            </p>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="lg:tw-hidden sm:tw-text-right">
          <v-card color="transparent" elevation="0">
            <p class="tw-text-lg tw-font-semibold mb-2">Business Incubator</p>
            <p class="">
              We look for ingenious and inspired ideas to invest in and offer
              technical support and help businesses secure funds.
            </p>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          sm="12"
          class="lg:tw-flex lg:tw-flex-col lg:tw-justify-between lg:tw-text-right sm:tw-hidden tw-hidden"
        >
          <v-card color="transparent" elevation="0">
            <p class="tw-text-lg tw-font-semibold mb-2">Product Consulting</p>
            <p class="">
              Our Expertise is to materialise innovative ideas, standardise
              technology stack, fast-track delivery time to market.
            </p>
          </v-card>
          <v-card color="transparent" elevation="0">
            <p class="tw-text-lg tw-font-semibold mb-2">Business Incubator</p>
            <p class="">
              We look for ingenious and inspired ideas to invest in and offer
              technical support and help businesses secure funds.
            </p>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'AdviceScreen'
}
</script>

<style scoped>
.underline {
  width: 48px;
  height: 4px;
  background: #78035a;
}
.underline-white {
  width: 48px;
  height: 4px;
  background: white;
}
</style>
