<template>
  <v-container pa-0 ma-0 fill-height fluid>
    <section class="lg:tw-mt-32 tw-w-full">
      <p
        class="tw-text-pink-800 tw-text-3xl sm:tw-text-4xl lg:tw-text-4xl tw-font-semibold tw-max-w-3xl mb-16 tw-pt-20"
      >
        Build innovative products, transform businesses
      </p>

      <v-row class="tw-pb-24">
        <v-col cols="12" xl="4" lg="4" sm="12" class="lg:tw-hidden">
          <v-img src="../assets/product-development.jpg" height="488" />
          <v-col cols="7" lg="6" class="tw--mt-32 tw-float-right tw-mr-10 pa-0">
            <v-card class="px-5 py-10" color="#77045A" tile>
              <p class="tw-text-white tw-text-2xl">Product Development</p>
              <p class="underline-white"></p>
            </v-card>
          </v-col>
        </v-col>
        <v-col cols="12" xl="8" lg="6" sm="12" class="lg:tw-mx-auto">
          <v-card class="tw-flex">
            <v-img
              class="tw-my-10 tw-ml-10"
              src="../assets/incubator.svg"
              max-height="50"
              max-width="50"
            />
            <div class="tw-mt-8 tw-mx-5">
              <p class="tw-font-semibold mb-2">Mobile App Development</p>
              <p class="tw-text-sm">
                AUTOMATON’s Mobile App Development service specialises in both
                iOS &amp; Android and has succeeded in bringing to life some of
                the world’s most finely crafted apps that have gained mass
                acceptance in international markets.
              </p>
            </div>
          </v-card>
          <v-card class="tw-flex tw-my-10">
            <v-img
              class="tw-my-10 tw-ml-10"
              src="../assets/incubator.svg"
              max-height="50"
              max-width="50"
            />
            <div class="tw-mt-8 tw-mx-5">
              <p class="tw-font-semibold mb-2">Web Development</p>
              <p class="tw-text-sm">
                AUTOMATON offers experts who can conceptualize, design, develop
                and build intuitive responsive websites and web applications
                that increase customer engagements by delivering the best user
                experience across world-class CMS platforms like WordPress,
                Drupal, Joomla, etc.
              </p>
            </div>
          </v-card>
          <v-card class="tw-flex">
            <v-img
              class="tw-my-10 tw-ml-10"
              src="../assets/incubator.svg"
              max-height="50"
              max-width="50"
            />
            <div class="tw-mt-8 tw-mx-5">
              <p class="tw-font-semibold mb-2">
                Personalised Product Development
              </p>
              <p class="tw-text-sm">
                Our expert team of software professionals are well-versed with
                enterprise business technologies and are capable of building
                enterprise software solutions that precisely match all your
                requirements and flawlessly integrate with existing enterprise
                software solutions.
              </p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" xl="4" lg="4" sm="12" class="tw-hidden lg:tw-block">
          <v-img src="../assets/product-development.jpg" height="488" />
          <v-col cols="7" lg="7" class="tw--mt-32 tw-float-right tw-mr-10 pa-0">
            <v-card class="px-5 py-10" color="#77045A" tile>
              <p class="tw-text-white tw-text-2xl">Product Development</p>
              <p class="underline-white"></p>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'ProductDevelopment'
}
</script>

<style scoped>
.underline {
  width: 48px;
  height: 4px;
  background: #78035a;
}
.underline-white {
  width: 48px;
  height: 4px;
  background: white;
}
</style>
