<template>
  <v-container pa-0 ma-0 fill-height fluid class="container-height">
    <section class="tw-w-full">
      <p
        class="tw-text-pink-800 tw-text-3xl sm:tw-text-4xl lg:tw-text-4xl tw-font-semibold tw-max-w-3xl mb-16 tw-pt-24"
      >
        Expert guidance in all your steps be picking the right technology stack
        or go to market strategy
      </p>
      <v-row class="tw-relative tw-pb-32">
        <v-col cols="12" xl="4" lg="4" sm="12">
          <v-img src="../assets/product-consulting.jpg" height="488" />
          <v-col cols="7" lg="6" class="tw--mt-32 tw-ml-10 pa-0">
            <v-card class="px-5 py-10" color="#77045A" tile>
              <p class="tw-text-white tw-text-2xl">Product Consulting</p>
              <p class="underline-white"></p>
            </v-card>
          </v-col>
        </v-col>
        <v-col
          cols="12"
          xl="8"
          lg="8"
          sm="12"
          class="lg:tw-pl-20 lg:tw-pr-10 tw-text-lg"
        >
          <p class="mb-10">
            Get Your Products to the market faster with on-demand expertise to
            accelerate your product design and market launch, access global
            markets, reduce costs, and mitigate risks and much more.<br />
          </p>
          <p class="tw-font-semibold">
            Our core product development and consulting offerings include
            consulting and software development services in:
          </p>
          <v-row>
            <v-col cols="12" lg="6" sm="6">
              <ul>
                <li>Product Road-map &amp; Management</li>
                <li>Product Engineering</li>
              </ul>
            </v-col>
            <v-col cols="12" lg="6" sm="6">
              <ul>
                <li>Professional Services</li>
                <li>Product Sustenance</li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'ProductConsulting'
}
</script>

<style scoped>
ul {
  list-style-type: square;
}

.underline-white {
  width: 48px;
  height: 4px;
  background: white;
}

@media screen and (min-width: 1264px) {
  .container-height {
    height: 690px;
  }
}
</style>
