<template>
  <v-container pa-0 ma-0 fill-height fluid>
    <section class="tw-w-full">
      <p
        class="tw-text-pink-800 tw-text-3xl sm:tw-text-4xl lg:tw-text-4xl tw-font-semibold tw-max-w-3xl mb-16 tw-pt-20"
      >
        Why Choose Automaton ?
      </p>
      <v-row>
        <v-col cols="12" xl="4" lg="4" sm="4" class="tw-font-semibold">
          <v-col cols="12" xl="6" lg="6" sm="12" class="tw-ml-auto tw-mr-12">
            <p class="lg:tw-text-right">Get expert advice with AUTOMATON</p>
            <p class="underline lg:tw-ml-auto"></p>
          </v-col>
        </v-col>
        <v-col cols="12" xl="8" lg="8" sm="8" class="lg:tw-px-12">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Let’s collaborate and make an impact in your product
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                We help businesses to build product and take it to market theirs
                product ideas. We also help in scaling the businesses,
                prototyping, patenting, and much more.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Expertise to build the things that matter for you.
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                We offer the industry’s most comprehensive suite of enterprise
                applications with strong capabilities in custom Enterprise
                Application Development, Enterprise Information Management with
                increased productivity and flexibility and improved analysis and
                reporting.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Future proofing with latest technologies
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                We offer many clients to take advantage of Block-Chain
                Technology in their applications to harvest the benefits of
                immutable data. Our expertise includes mass-scale
                blockchain-powered business ecosystems, building smart
                contracts, and much more.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row class="tw-py-32">
        <v-col cols="12">
          <p
            class="tw-font-semibold tw-text-pink-800 tw-text-center tw-text-2xl tw-pb-8"
          >
            Our Client
          </p>
        </v-col>
        <v-col cols="3">
          <v-card class="tw-mb-6" height="50"></v-card>
          <v-card class="tw-mb-6" height="50"></v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="tw-mb-6" height="50"></v-card>
          <v-card class="tw-mb-6" height="50"></v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="tw-mb-6" height="50"></v-card>
          <v-card class="tw-mb-6" height="50"></v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="tw-mb-6" height="50"></v-card>
          <v-card class="tw-mb-6" height="50"></v-card>
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="tw-relative tw-z-10"
        style="margin-bottom: -5%"
      >
        <v-col
          cols="12"
          xl="10"
          lg="10"
          sm="12"
          class="bg-color tw-rounded-2xl tw-px-8 sm:tw-px-12 lg:tw-px-12 tw-pt-12"
        >
          <h1
            class="tw-text-2xl sm:tw-text-3xl lg:tw-text-4xl xl:tw-text-4xl tw-text-white tw-pb-8"
          >
            Join our team and work at the cutting edge of technologies
          </h1>
          <p class="tw-text-right tw-text-white mb-12">
            Write to us at
            <a class="tw-font-semibold" href="mailto:hr@automaton.com"
              >hr@automaton.com</a
            >
          </p>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'Client'
}
</script>

<style scoped>
.underline {
  width: 48px;
  height: 4px;
  background: #78035a;
}
.bg-color {
  background: linear-gradient(
    144deg,
    rgba(217, 18, 101, 1) 0%,
    rgba(119, 4, 90, 1) 100%
  );
}

.v-application a {
  color: white;
}
</style>
