<template>
  <v-container pa-0 ma-0 fill-height fluid>
    <section class="lg:tw-mt-32 tw-pb-24 tw-w-full">
      <p
        class="tw-text-pink-800 tw-text-3xl sm:tw-text-4xl lg:tw-text-4xl tw-font-semibold tw-max-w-3xl mb-12 tw-pt-24"
      >
        Give shape to your ideas. Our incubation process enables you to innovate
        and evolve.
      </p>
      <v-row>
        <v-col cols="12" xl="8" lg="8" sm="12">
          <p class="lg:tw-pr-24 mb-10">
            We accelerate the growth and success of entrepreneurial companies
            through an array of business support resources and services that
            could include physical space, capital, coaching, common services,
            and networking connections.
          </p>
          <v-col cols="12" lg="10" class="lg:tw-pl-20">
            <p class="tw-font-semibold mb-10">
              We help you plan your business and turn your ingenious ideas into
              actionable businesses, launch you
            </p>
            <v-card class="tw-flex">
              <v-img
                class="tw-my-10 tw-ml-10"
                src="../assets/incubator.svg"
                max-height="60"
                max-width="60"
              />
              <div class="tw-mt-8 tw-mx-5">
                <p class="tw-font-semibold mb-2">Planning your Business</p>
                <p class="tw-text-sm">
                  Turn your ideas into an actionable, executable business plan
                </p>
              </div>
            </v-card>
            <v-card class="tw-flex tw-mt-10">
              <v-img
                class="tw-my-10 tw-ml-10"
                src="../assets/incubator.svg"
                max-height="60"
                max-width="60"
              />
              <div class="tw-mt-8 tw-mx-5">
                <p class="tw-font-semibold mb-2">Manage your Business</p>
                <p class="tw-text-sm">
                  Master day-to-day operations and prepare for success
                </p>
              </div>
            </v-card>
          </v-col>
        </v-col>
        <v-col cols="12" xl="4" lg="4" sm="12">
          <v-img src="../assets/pexels-thisisengineering-3912958.jpg" height="488" />
          <v-col cols="7" lg="6" class="tw--mt-32 tw-float-right tw-mr-10 pa-0">
            <v-card class="px-5 py-10" color="#77045A" tile>
              <p class="tw-text-white tw-text-2xl">Business Incubator</p>
              <p class="underline-white"></p>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="12" xl="6" lg="6" sm="12" class="lg:tw-mr-10 lg:tw-pl-6">
          <v-card class="tw-flex">
            <v-img
              class="tw-my-10 tw-ml-10"
              src="../assets/incubator.svg"
              max-height="60"
              max-width="60"
            />
            <div class="tw-mt-8 tw-mx-5">
              <p class="tw-font-semibold mb-2">Launch your Business</p>
              <p class="tw-text-sm">
                Turn your business plan into a reality (register, file, and
                start doing business)
              </p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" xl="5" lg="5" sm="12">
          <v-card class="tw-flex">
            <v-img
              class="tw-my-10 tw-ml-10"
              src="../assets/incubator.svg"
              max-height="60"
              max-width="60"
            />
            <div class="tw-mt-8 tw-mx-5">
              <p class="tw-font-semibold mb-2">Grow your Business</p>
              <p class="tw-text-sm">
                Find new funding, locations, and customers when business is good
                and it's time to expand.
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'BusinessIncubator'
}
</script>

<style scoped>
.underline {
  width: 48px;
  height: 4px;
  background: #78035a;
}
.underline-white {
  width: 48px;
  height: 4px;
  background: white;
}
</style>
