<template>
  <!-- <v-carousel>
      <v-carousel-item
        v-for="(slide,i) in slides"
        :key="i"
        :src="slide.src"
        reverse-transition="fade"
        transition="fade"
      ></v-carousel-item>
    </v-carousel> -->
  <v-container pa-0 ma-0 fill-height fluid>
    <section
      class="tw-h-screen/2 lg:tw-h-screen sm:tw-h-screen/2 tw-w-full tw-mx-auto"
    >
      <v-carousel
        class="lg:tw-h-screen sm:tw-h-screen/2"
        cycle
        width="100%"
        height="100%"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="slide.src">
          <v-sheet :color="colors" height="100%">
            <v-row class="fill-height" align="center" justify="center">
              <v-col cols="10">
                <h1
                  class="tw-font-bold tw-text-4xl sm:tw-text-6xl lg:tw-text-6xl xl:tw-text-6xl"
                >
                  {{ slide.title }}
                </h1>
                <p
                  class="tw-text-xl sm:tw-text-2xl lg:tw-text-2xl xl:tw-text-2xl"
                >
                  {{ slide.subtitle }}
                </p>
                <v-btn depressed href="mailto:info@automaton.com" color="#78035a" large class="px-12 py-6">{{ slide.button }}</v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'Carousel',
  data() {
    return {
      colors: '#00000099',
      slides: [
        {
          title: 'Expert Guidance',
          subtitle:
            'For startups and organizations grappling with scale and engineering decisions.',
          src: require('@/assets/business-incubator.jpg'),
          button: 'Get in touch'
        },
        {
          title: 'Incubate New Products',
          subtitle:
            'With a leaning towards learning platforms and data analytics, employing AI',
          src: require('@/assets/pexels-thisisengineering-3912958.jpg'),
          button: 'Get in touch'
        },
        {
          title: 'Extended Engineering Team',
          subtitle:
            'Take product ideas to Series A. We design, build and hand over fully stabilized product',
          src: require('@/assets/product-development.jpg'),
          button: 'Get in touch'
        },
      ],
    }
  },
}
</script>
