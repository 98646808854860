<template>
  <div>
    <!-- Redirects to home page on click -->
    <v-btn :ripple="false" text href="/" class="no-background-hover">
      <v-row justify="center" class="tw-flex-1 text-left">
        <v-col cols="12" class="tw-h-full tw-w-ful">
          <v-row no-gutters>
            <v-img contain
              src="../assets/automaton-logo_white.svg"
              max-height="64"
              max-width="64"
            />
            <p
              class="d-none d-sm-flex tw-text-white my-auto lg:tw-pl-4 md:tw-pl-2 sm:pl-1 lg:tw-text-3xl md:tw-text-2xl sm:tw-text-xl tw-font-medium"
            >
              automaton
            </p>
          </v-row>
        </v-col>
      </v-row>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'XLogo',
  computed: {
    // Returns true if device is of category xs, sm or md
    mobile() {
      return ['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name)
    },
  },
}
</script>

<style scoped>
.logoDiv {
  top: 12px;
  left: 40px;
  width: 32px;
  height: 32px;
  background: var(--main) 0% 0% no-repeat padding-box;
  background: #01adb9 0% 0% no-repeat padding-box;
  border: 1px solid #00000000;
  opacity: 1;
}

.no-background-hover::before {
  background-color: transparent !important;
}
</style>
