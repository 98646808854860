<template>
  <v-container pa-0 ma-0 fill-height fluid class="container-height">
    <section class="tw-w-full tw-pb-24">
      <p
        class="tw-text-pink-800 tw-text-3xl sm:tw-text-4xl lg:tw-text-4xl tw-font-semibold tw-max-w-3xl mb-16 tw-pt-24"
      >
        A technology partner you can bank on.
      </p>
      <v-row class="tw-relative">
        <v-col cols="12" xl="4" lg="4" sm="12">
          <v-img src="../assets/technical-advisory.jpg" height="488" />
          <v-col cols="7" lg="6" class="tw--mt-32 tw-ml-10 pa-0">
            <v-card class="px-5 py-10" color="#77045A" tile>
              <p class="tw-text-white tw-text-2xl">Technical Advisory</p>
              <p class="underline-white"></p>
            </v-card>
          </v-col>
        </v-col>
        <v-col
          cols="12"
          xl="8"
          lg="8"
          sm="12"
          class="lg:tw-pl-20 lg:tw-pr-10 tw-text-lg"
        >
          <p class="mb-10">
            AUTOMATON’s Technical Advisory works as an integral part of your
            team, bringing in cross-functional expertise to help you efficiently
            manage your projects. We understand your specific requirements and
            collaborate effectively with your team to help you produce the best
            results.
          </p>
          <p class="tw-font-semibold">
            We provide comprehensive support as a Technical Advisory like:
          </p>
          <v-row>
            <v-col cols="12" lg="6">
              <ul>
                <li class="lg:tw-h-16">
                  Ensure scalability to handle dynamic workloads
                </li>
                <li>Cross-functional expertise</li>
              </ul>
            </v-col>
            <v-col cols="12" lg="6">
              <ul>
                <li class="lg:tw-h-16">Verify ease of use of UI/UX design</li>
                <li>Business domain knowledge</li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'TechnicalAdvisory'
}
</script>

<style scoped>
ul {
  list-style-type: square;
}

.underline-white {
  width: 48px;
  height: 4px;
  background: white;
}

@media only screen and (min-width: 1264px) {
  .container-height {
    height: 650px;
  }
}
</style>
