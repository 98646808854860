<template>
  <!-- Main navigation bar -->
  <v-app-bar absolute flat color="transparent" height="128">
    <x-logo />
    <v-spacer></v-spacer>
    <div justify="right">
      <v-btn text href="#" color="white">About Us </v-btn>
      <v-btn text href="#" color="white"> Contact Us </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import XLogo from '@/components/XLogo'

export default {
  name: 'XNavBar',
  components: {
    XLogo
  },
  data() {
    return {
      appTitle: 'Automaton',
      drawer: false,
      isMenuOpen: false,
      selected: {},
      sideMenuItems: [
        { title: 'Courses', url: '/courses' },
        { title: 'Career Path', url: '/career' }
      ],
      windowSize: {
        x: 0,
        y: 0
      },
      userMenuItems: [
        {
          title: 'User Profile',
          url: '/getprofile'
        },
        { title: 'Logout', url: '/' }
      ]
    }
  }
}
</script>
