<template>
  <v-container fluid pa-0 ma-0>
    <section>
      <!-- <v-row
        no-gutters
        justify="center"
        class="tw-w-full lg:tw-h-screen sm:tw-h-screen/2 bg-color"
        style="border: solid 0px blue"
      >
        <v-col cols="10" md="10" lg="10" xl="10" style="border: solid 0px pink">
          <title-block :sectionTitle="videoStartTitle" />
        </v-col>
      </v-row> -->
      <v-row
        no-gutters
        justify="center"
        class="tw-w-full lg:tw-h-screen sm:tw-h-screen/2"
        style="border: solid 0px blue"
      >
        <v-col cols="12" md="12" lg="12" xl="12" style="border: solid 0px pink">
          <carousel />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        style="border: solid 0px blue"
        class="tw-bg-gray-200"
      >
        <v-col cols="10" md="10" lg="10" xl="10" style="border: solid 0px red">
          <advice-screen />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" style="border: solid 0px blue">
        <v-col cols="10" md="10" lg="10" xl="10" style="border: solid 0px red">
          <technical-advisory />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        style="border: solid 0px blue"
        class="tw-bg-gray-200"
      >
        <v-col cols="10" md="10" lg="10" xl="10" style="border: solid 0px red">
          <product-development />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" style="border: solid 0px blue">
        <v-col cols="10" md="10" lg="10" xl="10" style="border: solid 0px red">
          <product-consulting />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        style="border: solid 0px blue"
        class="tw-bg-gray-200"
      >
        <v-col cols="10" md="10" lg="10" xl="10" style="border: solid 0px red">
          <business-incubator />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        style="border: solid 0px blue"
        class="tw-bg-gray-200"
      >
        <v-col cols="10" md="10" lg="10" xl="10" style="border: solid 0px red">
          <client />
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
// @ is an alias to /src
// import TitleBlock from '@/components/TitleBlock.vue'
import AdviceScreen from '@/components/AdviceScreen.vue'
import TechnicalAdvisory from '@/components/TechnicalAdvisory.vue'
import ProductDevelopment from '@/components/ProductDevelopment.vue'
import ProductConsulting from '@/components/ProductConsulting.vue'
import BusinessIncubator from '@/components/BusinessIncubator.vue'
import Client from '@/components/Client.vue'
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'Home',
  components: {
    AdviceScreen,
    // TitleBlock,
    TechnicalAdvisory,
    ProductDevelopment,
    ProductConsulting,
    BusinessIncubator,
    Client,
    Carousel
  },
  data: function() {
    return {
      footerSettings: {
        type: 'normal'
      },
      videoStartTitle: {
        title: 'Actualising your dreams with automaton.'
      }
    }
  }
}
</script>

<style scoped>
.bg-color {
  background: linear-gradient(
    144deg,
    rgba(217, 18, 101, 1) 0%,
    rgba(119, 4, 90, 1) 100%
  );
}
</style>
