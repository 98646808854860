<template>
  <v-footer padless dense color="white">
    <!-- Footer - Normal Type : Starts -->
    <!-- There are 3 different footer layouts, (3 different containers, only one will be displayed at a time)
            1. Normal Footer - Desktop 
            2. Normal Footer - Mobile devices
            3. Mini Footer - Same for all devices
      -->

    <!-- Desktop Footer : Start (Type:Normal) -->
    <!-- For this footer to display pass settings prop with type ='normal' -->
    <v-container
      class="hidden-sm-and-down tw-pt-32"
      v-if="settings.type == 'normal'"
    >
      <!-- The Line-1 of the footer -->
      <v-row class="tw-pb-16">
        <!-- Logo -->
        <!-- Occupies 2/12th of screen width, left alinged -->
        <v-col class="text-left" cols="2">
          <v-img
            src="../assets/automaton-logo.svg"
            max-height="96"
            max-width="96"
          />
        </v-col>

        <v-spacer />

        <!-- Menu Option -->
        <!-- Occupies 7/12th of screen width, left alinged -->
        <v-col class="text-left" cols="2">
          <v-btn href="" class="tw-font-bold text-capitalize tw-text-sm" text>
            About Us
          </v-btn>
        </v-col>

        <v-col class="text-left" cols="2">
          <v-btn href="" class="tw-font-bold text-capitalize tw-text-sm" text>
            Contact Us
          </v-btn>
        </v-col>

        <v-col class="text-left" cols="2">
          <v-btn href="" class="tw-font-bold text-capitalize tw-text-sm" text>
            Services
          </v-btn>
        </v-col>

        <!-- <v-col class="text-left" cols="2">
          <v-btn
            href=""
            class="tw-font-bold text-capitalize tw-text-sm"
            text
          >
            About
          </v-btn>
        </v-col> -->
      </v-row>
    </v-container>

    <v-row class="hidden-sm-and-down tw-text-block border-top">
      <v-col cols="10" class="tw-mx-auto tw-py-8">
        Copyright © 2020 Automaton. All rights reserved.
      </v-col>
    </v-row>
    <!-- Desktop Footer : End (Type:Normal) -->

    <!-- Mobile Footer : Start (Type:Normal) -->
    <!-- For this footer to display pass settings prop with type ='normal' -->
    <v-container
      pa-0
      ma-0
      fill-height
      fluid
      class="hidden-md-and-up tw-w-full"
      v-if="settings.type == 'normal'"
    >
      <!-- The Line-2 of the mobile-footer -->
      <!-- Menu Options -->
      <v-row class="sm:tw-px-8 sm:tw-mt-10 sm:ml-10" justify="center">
        <v-col cols="10" sm="3">
          <!-- The Line-1 of the mobile-footer -->
          <!-- Logo -->
          <v-img
            src="../assets/automaton-logo.svg"
            max-height="96"
            max-width="96"
          />
        </v-col>
        <v-col cols="10" sm="3" align-self="center">
          <v-btn href="" class="tw-font-bold text-capitalize tw-text-sm" text>
            About Us
          </v-btn>
        </v-col>

        <v-col cols="10" sm="3" align-self="center">
          <v-btn href="" class="tw-font-bold text-capitalize tw-text-sm" text>
            Contact Us
          </v-btn>
        </v-col>

        <v-col cols="10" sm="3" align-self="center">
          <v-btn href="" class="tw-font-bold text-capitalize tw-text-sm" text>
            Services
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-row class="hidden-md-and-up tw-text-block border-top">
      <v-col cols="10" class="tw-mx-auto tw-py-8">
        Copyright © 2020 Automaton. All rights reserved.
      </v-col>
    </v-row>
    <!-- Mobile Footer : End (Type:Normal)-->
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  props: ['settings']
}
</script>

<style scoped>
.border-top {
  border-top: 1px solid #e2e8f0;
}

.v-btn {
  font-size: 18px;
}
</style>
